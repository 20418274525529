import React, { useState, useEffect } from "react"
import styled from 'styled-components'
import moment from "moment"
import 'moment/locale/es'
import { FormattedMessage } from 'gatsby-plugin-intl'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts"
import { intcomma } from "journalize"

const TIMERANGE = { start: 946684800, end: 1514764800 }
const YDOMAIN_YEAR = [0, 36000]
const YDOMAIN_MONTH = [0, 3600]

const Graph = styled.div`
  margin-bottom: 10px;
`

function getChargeCount (dataKey, payload) {
  const chargeObject = payload.filter(f => f.dataKey === dataKey)[0]
  return chargeObject ? intcomma(chargeObject.value) : 0
}

const CustomTooltip = (props) => {
  if (props.active) {
    return <TooltipWrapper>
      { props.timeGroup === 'year' &&
        <h3>{moment.unix(props.label).utc().locale(props.intl.locale).format("YYYY")}</h3>
      }
      { props.timeGroup === 'month' &&
        <h3>{moment.unix(props.label).utc().locale(props.intl.locale).format('MMM YYYY')}</h3>
      }
      <p>
        <FormattedMessage
          id="charges.chart.initiated-label-misdemeanor"
          defaultMessage={`{cases_m} misdemeanor cases initiated`}
          values={{cases_m: getChargeCount("charges_m", props.payload)}}
        />
      </p>
      <p>
        <FormattedMessage
          id="charges.chart.initiated-label-felony"
          defaultMessage={`{cases_f} felony cases initiated`}
          values={{cases_f: getChargeCount("charges_f", props.payload)}}
        />
      </p>
    </TooltipWrapper>
  }

  return null
}

const TooltipWrapper = styled.div`
  background-color: white;
  border: none;
  padding: 5px;
  font-size: .5rem;
  p, h3 { margin: 0; }
  h3 {
      font-size: 1rem;
      font-weight: bold;
  }
  p {
    font-size: 1rem;
    padding: 0;
  }
`

const StackedAreaChart = ({data, timeGroup, intl}) => {
  const [locale, setLocale] = useState()

  useEffect(() => {
    setLocale(intl.locale)
  })

  function formatXAxisYear (tickItem, intl) {
    return moment.unix(tickItem).utc().locale(locale).format('YYYY')
  }
  
  function formatXAxisMonth (tickItem, intl) {
    return moment.unix(tickItem).utc().locale(locale).format('MMM YYYY')
  }
  return (
    <Graph>
      <ResponsiveContainer height={200} width="100%">
        <AreaChart
          data={data}
          margin={{right: 20}}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="case_date"
            scale="time"
            type="number"
            domain={[TIMERANGE.start, TIMERANGE.end]}
            tickFormatter={timeGroup === 'year' ? formatXAxisYear : formatXAxisMonth}
            interval={timeGroup === 'year' ? 2 : 47}
          />
          <YAxis
            width={45}
          />
          <Area
            stackId="1"
            dataKey="charges_f"
            fill="#F2B661"
            stroke="#F09518"
            fillOpacity="1"
          />
          <Area
            stackId="1"
            dataKey="charges_m"
            fill="#956EFF"
            stroke="#6A00F0"
            fillOpacity="1"
          />
          <Tooltip
            content={
              <CustomTooltip
                timeGroup={timeGroup}
                intl={intl}
              />
            }
          />
        </AreaChart>
      </ResponsiveContainer>
    </Graph>
  )
}

const IndexStackedAreaChart = ({data, timeGroup, intl}) => {
  return (
    <Graph className="index-area-chart">
      <ResponsiveContainer aspect={4.8}>
        <AreaChart
          data={data}
        >
          <YAxis
            domain={timeGroup === 'year' ? YDOMAIN_YEAR : YDOMAIN_MONTH}
            hide
          />
          <XAxis
            dataKey="case_date"
            scale="time"
            type="number"
            domain={[TIMERANGE.start, TIMERANGE.end]}
            hide
          />
          <Area
            stackId="1"
            dataKey="charges_f"
            fill="#F2B661"
            stroke="#F09518"
            fillOpacity="1"
          />
          <Area
            stackId="1"
            dataKey="charges_m"
            fill="#956EFF"
            stroke="#6A00F0"
            fillOpacity="1"
          />
          <Tooltip
            position={{ y: 50 }}
            content={
              <CustomTooltip
                timeGroup={timeGroup}
                intl={intl}
              />
            }
          />
        </AreaChart>
      </ResponsiveContainer>
    </Graph>
  )
}

export { StackedAreaChart, IndexStackedAreaChart }
