import { capfirst } from "journalize"

const getCurrentCharge = (vizState, charges) => {
  var currentCharge = vizState.charge === 'all' ? 'all' : charges.filter(d => d.ucr.charge_slug === vizState.charge)[0]
  return currentCharge
}

const getChargeDescription = (charge, intl) => {
  if (charge === 'all') {
    return intl.formatMessage(
      {
        id: "headers.all",
        defaultMessage: "All"
      }
    )
  } else {
    return intl.formatMessage(
      {
        id: "charges.description",
        defaultMessage: capfirst(charge.ucr.description)
      },
      {
        description: capfirst(charge.ucr.description),
        description_es: capfirst(charge.ucr.description_es)
      }
    )
  }
}

const getCategoryLabel = (data, intl) => {
  const category = data.Courts.category[0].offenseCategoryByOffenseCategory
  return intl.formatMessage(
    {
      id: "offense_categories.name",
      defaultMessage: capfirst(category.offense_category)
    },
    {
      offense_category_es: capfirst(category.offense_category_es),
      offense_category: capfirst(category.offense_category)
    }
  )
}

const getHTML = (data) => {
  const {
    current,
    default: {
      html: defaultHTML,
    },
  } = data
  return current ? current.html : defaultHTML
}

export { getCurrentCharge, getChargeDescription, getHTML, getCategoryLabel }
