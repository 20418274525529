import React from "react"
import { Col, Row } from "react-bootstrap"

const OffenseText = ({category, intl, children}) => {
  return (
    <Row>
      <Col sm={4} style={{paddingRight: '1.8rem'}}>
        <p>
          <strong>
            {intl.formatMessage(
              {
                id: "offense_categories.what_is_it_header",
                defaultMessage: "What is it?"
              }
            )}
          </strong>
        </p>
        <p>
          <i>
            {intl.formatMessage(
              {
                id: "offense_categories.what_is_it",
                defaultMessage: category.what_is_it ? category.what_is_it : "No description yet available."
              },
              {
                what_is_it: category.what_is_it,
                what_is_it_es: category.what_is_it_es
              }
            )}
          </i>
        </p>
        {children}
      </Col>
      <Col sm={8}>
        <p>
          <strong>
            {intl.formatMessage(
              {
                id: "offense_categories.data_shows_header",
                defaultMessage: "What does our data show?"
              }
            )}
          </strong>
        </p>
        <p
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage(
              {
                id: "offense_categories.data_shows",
                defaultMessage: category.data_shows ? category.data_shows : "No description yet available."
              },
              {
                data_shows: category.data_shows,
                data_shows_es: category.data_shows_es
              }
            )
          }}
        />
      </Col>
    </Row>
  )
}

export default OffenseText
