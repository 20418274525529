import React from "react"
import { injectIntl } from "gatsby-plugin-intl"

const Legend = (props) => {
  return (
    <ul className="legend">
      <li>
        <span className="orange"></span> {props.intl.formatMessage(
          {
            id: "charges.types.F",
            defaultMessage: "Felony"
          }
        )}
      </li>
      <li>
        <span className="purple"></span> {props.intl.formatMessage(
          {
            id: "charges.types.M",
            defaultMessage: "Misdemeanor"
          }
        )}
      </li>
    </ul>
  )
}

export default injectIntl(Legend)
