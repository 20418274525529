import React from "react"
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"

import { getCurrentCharge, getChargeDescription } from "../components/utils"

const TimeGroupDropdown = (props) => {
  var label

  if (props.charges) {
    const chargeDescription = getChargeDescription(
      getCurrentCharge(props.vizState, props.charges),
      props.intl
    )
    label = `${chargeDescription} ${
      props.intl.formatMessage({
        id: "headers.casesby",
        defaultMessage: "cases by"
      })
    }`
  } else {
    label = props.intl.formatMessage({
      id: "headers.countcasesby",
      defaultMessage: "Count cases by"
    })
  }

  return (
    <div className="timegroup-dropdown">
      <label>
        {label}
        <select
          value={props.vizState.timeGroup}
          onChange={e => props.vizState.setTimeGroup(e.target.value)}
        >
          <FormattedMessage id="headers.year">
            {(message) => <option value="year">{message}</option>}
          </FormattedMessage>
          <FormattedMessage id="headers.month">
            {(message) => <option value="month">{message}</option>}
          </FormattedMessage>
        </select>
      </label>
    </div>
  )
}

export default injectIntl(TimeGroupDropdown)
