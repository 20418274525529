import React from "react"


const Hero = (props) => (
  <section className="hero">
    <div>
      <p className="label">{props.label}</p>
      {props.children}
    </div>
  </section>
)

export default Hero
